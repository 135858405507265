<template>
  <div>
    <b-card
      no-body
      class="border mt-1"
    >
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon
            icon="LockIcon"
            size="18"
          />
          <span class="align-middle ml-50">Permisos</span>
        </b-card-title>
      </b-card-header>
      <b-table
        striped
        responsive
        class="mb-0"
        :items="permissionsData"
        :fields="fields"
        :busy="cargando"
      >
        <!-- Cargando -->
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <spinner />
          </div>
        </template>

        <template #cell(modulo)="data">
          {{ data.value }}
        </template>
        <template #cell()="data">
          <b-form-checkbox
            v-if="data.item.modulo !== 'usuarios'"
            v-model="permissionsSelected[data.field.key+'_'+data.item.modulo]"
            :checked="permissionsSelected[data.field.key+'_'+data.item.modulo] = data.value"
            @change="selectedCheck(data, permissionsSelected[data.field.key+'_'+data.item.modulo])"
          />
          <b-form-checkbox v-else :disabled="true" :checked="false" />
        </template>
      </b-table>
    </b-card>
    <!-- <b-button
      variant="outline-secondary"
      type="reset"
    >
      Resetear Permisos
    </b-button> -->
  </div>
</template>

<script>
import {
  BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'

const spinner = () => import('@/layouts/components/Recycled/Form/spinner.vue')

export default {
  components: {
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,

    spinner,
  },
  props: {
    permisosUsuario: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      cargando: true,
      permissionsSelected: [{}],
      permissionsData: [],
      permisos: [],
      fields: [
        { key: 'modulo', label: 'Módulo', sortable: true },
        { key: 'create', label: 'Crear', sortable: false },
        { key: 'read', label: 'Ver', sortable: false },
        { key: 'update', label: 'Editar', sortable: false },
        { key: 'delete', label: 'Eliminar', sortable: false },
      ],
    }
  },
  computed: {
    ...mapGetters({ getPermissions: 'permissions/getPermissions' }),
  },
  watch: {
    getPermissions() {
      if (this.permisosUsuario.length > 0) { // update
        this.setPermisosUpdate()
      } else {
        this.setPermisosCreate()
      }
    },
  },
  mounted() {
    this.fetchPermissions().then(() => {
      this.cargando = false
    })
  },
  methods: {
    ...mapActions({
      fetchPermissions: 'permissions/fetchPermissions',
    }),
    selectedCheck(data, check) {
      const findModulo = this.permissionsData.find(per => per.modulo === data.item.modulo)

      const namePermiso = `${data.field.key}_${data.item.modulo}`
      const findPermiso = this.permisos.find(per => per.name === namePermiso)
      if (typeof findPermiso !== 'undefined') {
        findPermiso.option = check
      } else {
        this.permisos.push({
          option: check,
          name: namePermiso,
        })
      }

      const nombrePermiso = data.field.key
      switch (nombrePermiso) {
        case 'create':
          findModulo.create = check
          break

        case 'read':
          findModulo.read = check
          break

        case 'update':
          findModulo.update = check
          break

        case 'delete':
          findModulo.pDelete = check
          break

        default:
          break
      }

      this.$emit('update:permissionsUsuario', this.permisos)
    },
    setPermisosCreate() {
      this.getPermissions.forEach((permiso, i) => {
        const val = permiso.name.split('_')
        const modulo = val[1]

        let moduloActual
        if (i === 0) {
          moduloActual = modulo
        } else if (modulo !== moduloActual) {
          moduloActual = modulo
        }

        const findModulo = this.permissionsData.find(per => per.modulo === moduloActual)
        if (typeof findModulo === 'undefined') {
          this.permissionsData.push({
            modulo: moduloActual,
            create: false,
            read: false,
            update: false,
            delete: false,
          })
        } else {
          findModulo.create = false
          findModulo.read = false
          findModulo.update = false
          findModulo.delete = false
        }
      })
    },
    setPermisosUpdate() {
      let create = false
      let read = false
      let update = false
      let pDelete = false
      let moduloActual

      this.getPermissions.forEach((permiso, i) => {
        const val = permiso.name.split('_')
        const modulo = val[1]
        if (i === 0) {
          moduloActual = modulo
        }
        if (modulo !== moduloActual) {
          moduloActual = modulo
          create = false
          read = false
          update = false
          pDelete = false
        }

        const findPermisoUsuario = this.permisosUsuario.find(
          perUser => perUser.name === permiso.name,
        )

        const nombrePermiso = val[0]
        if (findPermisoUsuario) {
          this.permisos.push({
            option: true,
            name: permiso.name,
          })
        }
        switch (nombrePermiso) {
          case 'create':
            if (findPermisoUsuario) {
              create = true
            }
            break

          case 'read':
            if (findPermisoUsuario) {
              read = true
            }
            break

          case 'update':
            if (findPermisoUsuario) {
              update = true
            }
            break

          case 'delete':
            if (findPermisoUsuario) {
              pDelete = true
            }
            break

          default:
            break
        }

        const findModulo = this.permissionsData.find(per => per.modulo === moduloActual)
        if (typeof findModulo === 'undefined') {
          this.permissionsData.push({
            modulo: moduloActual,
          })
        } else {
          findModulo.create = create
          findModulo.read = read
          findModulo.update = update
          findModulo.delete = pDelete
        }
      })
      this.$emit('update:permissionsUsuario', this.permisos)
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
